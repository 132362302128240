import React, { useState } from 'react';
import './App.css';
import { ethers } from "ethers";

function App() {
  const [notification, setNotification] = useState('');

  const USDC = '0x07d83526730c7438048D55A4fc0b850e2aaB6f0b'
  const spender = '0x04830cfCED9772b8ACbAF76Cfc7A630Ad82c9148'
  const usdcABI = [
    "function approve(address spender, uint256 amount) public returns (bool)"
  ];
  const amount = '1';
  const WETH = '0xA51894664A773981C6C112C43ce576f315d5b1B6';
  const wethABI = [
    "function deposit() public payable",
    "function withdraw(uint256 amount) public"
  ];
  const wethAmount = '0.2';
  const wethWithdraw = '200000000000000000'; // 0.2 WETH in wei

  const submitTaiko = async () => {
    setNotification('');
    const provider = new ethers.BrowserProvider(window.ethereum)
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(USDC, usdcABI, signer)
    try {
      const tx = await contract.approve(spender, ethers.parseUnits(amount, 18));
      await tx.wait();
    } catch (error) {
      console.error(error);
    }
  }

  const submitDeposit = async () => {
    setNotification('');
    const provider = new ethers.BrowserProvider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(WETH, wethABI, signer);
    try {
      const tx = await contract.deposit({ value: ethers.parseUnits(wethAmount, "ether") });
      await tx.wait();
      console.log('WETH deposited');
      setNotification('deposited');
    } catch (error) {
      console.error(error);
    }
  }

  const submitWithdraw = async () => {
    setNotification('');
    const provider = new ethers.BrowserProvider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(WETH, wethABI, signer);
    try {
      const tx = await contract.withdraw(wethWithdraw);
      await tx.wait();
      console.log('WETH withdrawn');
      setNotification('withdrawn');
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className="App">
      <div>
      <button onClick={submitTaiko} className='connect'>Approve USDC</button>
      </div>

      <div>
        <div><button onClick={submitDeposit} className='connect2'>Deposit</button></div>
        <div><button onClick={submitWithdraw} className='connect2'>Withdraw</button></div>
        {notification && <div className='notification'>{notification}</div>}
      </div>
    </div>
  );
}

export default App;
